@import '~app/mixins';

.import {
  color: $color-grey-1;
  padding: 0;
  align-items: flex-end;

  .inner {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 18px;
      color: $color-grey-1;
      background-color: #eceffc;
      padding: 40px;

      .icon {
        width: 130px;
        height: 100px;
      }

      .fileName {
        margin-top: 20px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        .button {
          margin: 0 6px;
          width: 175px;
        }
      }
    }

    .jobs {
      font-size: 13px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex: 1;
      overflow-y: auto;

      table {
        border-collapse: collapse;

        th,
        td {
          padding: 8px 10px 0;

          &.center {
            text-align: center;
          }

          &.processing {
            font-style: italic;
          }

          &.left {
            text-align: left;
          }
        }

        th {
          padding-bottom: 4px;
          border-bottom: 1px solid $color-grey-1;

          &:first-child {
            border-bottom-style: none;
          }
        }

        .actions {
          width: 90px;

          div {
            visibility: hidden;
            white-space: nowrap;

            svg {
              height: 24px;
              width: 24px;
              cursor: pointer;
              margin: 0 4px;
              fill: $color-grey-light;

              &:hover {
                @include primary-fill-color-dark();
              }
            }
          }
        }

        .deleted {
          text-decoration: line-through;
        }

        tr:hover {
          td {
            background-color: #fffae1;
          }

          .actions {
            background-color: transparent;

            > div {
              visibility: visible;
            }
          }
        }
      }
    }

    .countdown {
      margin: 20px 0 10px;
      text-align: center;
      font-style: italic;
    }
  }
}
